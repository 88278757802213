// Начальная позиция слайдера - начинаем с первого
var position = 0;
// Сколько одновременнно видно слайдов
const slidesToShow = 2;
// Сколько слайдов прокручивается одновременно
const slidesToScroll = 1;
// Присваиваем переменную контейнеру слайдов
const container = document.querySelector(".slider__container");
// Присваиваем переменную дорожки слайдов
const track = document.querySelector(".slider__track");
// Присваиваем переменную самому блоку слайда, который несет информацию ввиде текста и картинок
const items = document.querySelectorAll(".slider__item");
// Присваиваем переменную кнопкам
const btnPrev = document.querySelector(".btn-prev");
const btnNext = document.querySelector(".btn-next");
// Присваиваем переменную, которая определяет сколько блоков слайдов есть
const itemsCount = items.length;
// Присваеваем переменной длину самого блока взяв длину контейнера (clientWidth) разделив ее на количество видимых элементов
const itemWidth = container.clientWidth / slidesToShow;
// Присваиваем переменную определяющую позицию слайдов взяв количество перемещающихся слайдов и длину самого слайда
const movePosition = slidesToScroll * itemWidth;

// функция переберает весь слайдер определяя сколько элементов в нем
items.forEach((item) => {
  item.style.minWidth = `${itemWidth}px`;
});

// Присваиваем кнопкам слушатель событий ввиде клика
btnNext.addEventListener("click", () => {
  const itemsLeft =
    itemsCount - (Math.abs(position) + slidesToShow * itemWidth) / itemWidth;

  position -=
    itemsLeft >= slidesToScroll ? movePosition : itemsLeft * itemWidth;

  setPosition();
  checkBtns();
});

// Присваиваем кнопкам слушатель событий ввиде клика
btnPrev.addEventListener("click", () => {
  const itemsLeft = Math.abs(position) / itemWidth;

  position +=
    itemsLeft >= slidesToScroll ? movePosition : itemsLeft * itemWidth;

  setPosition();
  checkBtns();
});

const setPosition = () => {
  track.style.transform = `translateX(${position}px)`;
};

const checkBtns = () => {
  btnPrev.disabled = position === 0;
  btnNext.disabled = position <= -(itemsCount - slidesToShow) * itemWidth;
};
checkBtns();
