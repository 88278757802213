// Zoom
$(function () {
  $(".slider__item-photo").click(function (event) {
    const i_pathS = $(this).attr("src");
    $("body").append(
      '<div id="bg__slider-zoom"></div><div id="slider-zoom__container"><img src="' +
        i_pathS +
        '"><div id="slider-zoom__close-popup"><i></i></div></div>'
    );
    $("#slider-zoom__container").css({
      left:
        ($(document).width() - $("#slider-zoom__container").outerWidth()) / 2,
      top: ($(window).height() - $("#slider-zoom__container").outerHeight()) / 2
    });

    $("#bg__slider-zoom, #slider-zoom__container").fadeIn("fast");
  });

  $("body").on(
    "click",
    "#slider-zoom__close-popup, #bg__slider-zoom",
    function (event) {
      event.preventDefault();

      $("#bg__slider-zoom, #slider-zoom__container").fadeOut(
        "fast",
        function () {
          $(
            "#slider-zoom__close-popup, #slider-zoom__container, #bg__slider-zoom"
          ).remove();
        }
      );
    }
  );
});
