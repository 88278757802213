// const init = () => {
//   var myMap = new ymaps.Map("map", {
//     center: [45.149948, 38.990467],
//     zoom: 18,
//     controls: ['zoomControl', 'fullscreenControl'],
//     duration: 1000,
//     timingFunction: "ease-in"
//   });
//
//   const coords = [
//     [45.149948, 38.990467]
//   ];
//
//   const myCollection = new ymaps.GeoObjectCollection({}, {
//     draggable: false,
//     iconLayout: 'default#image',
//     iconImageHref: 'assets/images/map/map_click.png',
//     iconImageSize: [58, 60],
//     iconImageOffset: [-30, -72]
//   });
//
//   coords.forEach(coord => {
//     myCollection.add(new ymaps.Placemark(coord));
//   });
//
//   myMap.geoObjects.add(myCollection);
//
//   myMap.behaviors.disable('scrollZoom');
// }
//
// ymaps.ready(init);
