// Zoom
$(function () {
  $(".fasad__img, .slider__img").click(function (event) {
    const i_path = $(this).attr("src");
    $("body").append(
      '<div id="overlay"></div><div id="magnify"><img src="' +
        i_path +
        '"><div id="close-popup"><i></i></div></div>'
    );
    $("#magnify").css({
      left: ($(document).width() - $("#magnify").outerWidth()) / 2,
      top: ($(window).height() - $("#magnify").outerHeight()) / 2
    });

    $("#overlay, #magnify").fadeIn("fast");
  });

  $("body").on("click", "#close-popup, #overlay", function (event) {
    event.preventDefault();

    $("#overlay, #magnify").fadeOut("fast", function () {
      $("#close-popup, #magnify, #overlay").remove();
    });
  });
});

$(function () {
  $(".fasad__img-profile").click(function (event) {
    const i_pathP = $(this).attr("src");
    $("body").append(
      '<div id="overlay"></div><div id="magnify-profile"><img src="' +
        i_pathP +
        '"><div id="close-popup-profile"><i></i></div></div>'
    );
    $("#magnify-profile").css({
      left: ($(document).width() - $("#magnify-profile").outerWidth()) / 2,
      top: ($(window).height() - $("#magnify-profile").outerHeight()) / 2
    });

    $("#overlay, #magnify-profile").fadeIn("fast");
  });

  $("body").on("click", "#close-popup-profile, #overlay", function (event) {
    event.preventDefault();

    $("#overlay, #magnify-profile").fadeOut("fast", function () {
      $("#close-popup-profile, #magnify-profile, #overlay").remove();
    });
  });
});
