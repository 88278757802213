let nav = $("#jsnav");
let burger = $("#jsburger");
let body = $('body');

burger.on("click", function(event) {
    event.preventDefault();
    burger.toggleClass("active");
    nav.toggleClass("show");
    body.toggleClass("no-scroll");
});


